import Link from 'next/link'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'

import styles from '@styles/pagesStyles/404.module.sass'

const notFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h1>404</h1>
      </div>

      <div className={styles.smallHeaderContainer}>
        <p>На жаль, сталася помилка. Сторінку не знайдено</p>
      </div>

      <div className={styles.smallHeaderContainer}>
        <p>
          Повернутися на <Link href={'/'}>ГОЛОВНУ СТОРІНКУ</Link>
        </p>
      </div>
    </div>
  )
}

export default notFound
